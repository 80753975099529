import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { NetworkLogo } from '../../../assets/networks';
import { Loading } from '../../../assets/icons/loading';
import { getBalances, getWalletById, hideAsset } from '../../../store/wallets';

const OwnerAccounts = ({ wallet }) => {
  const { walletId } = wallet;

  const balances = useSelector(state => state?.wallets[walletId]?.balances);
  const accounts = useSelector(state => {
    if (state?.wallets[walletId]?.accounts) {
      return Object.keys(state?.wallets[walletId]?.accounts).map(network => ({
        ...state?.wallets[walletId]?.accounts[network],
        network,
      }));
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        const res = await dispatch(getWalletById(controller.signal, walletId));
        if (res && res.accounts) {
          await dispatch(getBalances(controller.signal, { walletId }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    return () => {
      controller.abort();
    };
  }, [walletId, dispatch]);

  const handleHideAsset = async (network, asset) => {
    toast.dismiss();
    const loading = toast.loading('Working...');
    await dispatch(hideAsset({ walletId, network, asset }));
    toast.dismiss(loading);
  };

  if (!wallet) {
    return (
      <div className="p-10">
        <Loading />
      </div>
    );
  }

  if (!accounts || accounts.length === 0) {
    return <div className="text-center text-md py-16">Wallet {walletId} has no accounts.</div>;
  }

  return (
    <div className="xl:px-28">
      <h3 className="py-4 text-xl text-gray-100">Accounts</h3>
      {accounts.map(acct =>
        acct.assets.map(asset => (
          <div
            key={acct.address + asset}
            className={`bg-gradient-to-br from-slate-700 to-slate-800/75 overflow-y-hidden relative ${
              !acct.active ? 'bg-red-500' : ''
            }`}
          >
            <NetworkLogo network={acct.token} token={asset} size="m" />
            <div className="flex flex-col justify-center h-[140px] text-white">
              <div className="flex space-x-2 justify-end text-xl pr-5">
                <div>{asset.toUpperCase()}</div>
                {acct.active || <div className="text-sm text-red-500">Disabled</div>}
              </div>
            </div>
            <div className="flex-col flex items-center justify-center rounded-b">
              <div className="h-[140px] w-full p-5 transition-all origin-bottom transform absolute top-0 z-50">
                <div className="float-right">
                  <button
                    className="text-xs flex items-center border rounded p-1 hover:text-red-500 hover:border-red-500 duration-200"
                    onClick={() => handleHideAsset(acct.network, asset)}
                  >
                    Hide Asset
                  </button>
                </div>
                <div className="flex">
                  <div className="opacity-60 w-[180px] pl-52">Status</div>
                  <div className="w-[400px] pl-32">
                    <div className={acct.active || 'hidden'}>
                      <span className={`text-green-500 ${wallet.active ? '' : 'hidden'}`}>
                        Active
                      </span>
                      <span className={`text-red-500 ${wallet.active ? 'hidden' : ''}`}>
                        Wallet Disabled
                      </span>
                    </div>
                    <span className={`text-red-500 ${acct.active ? 'hidden' : ''}`}>
                      Account Disabled
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div className="opacity-60 w-[180px] pl-52">Address</div>
                  <div className="pl-32">{acct.address}</div>
                </div>
                <div className="flex">
                  <div className="opacity-60 w-[180px] pl-52">Balance</div>
                  <div className="pl-32">
                    <TokenBalance asset={asset} balances={balances} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )),
      )}
    </div>
  );
};

function TokenBalance({ asset, balances }) {
  return useMemo(() => {
    if (!balances) {
      return <div className="h-36 text-yellow-300 animate-pulse">Loading...</div>;
    }

    if (balances.error) {
      return <div className="h-36 text-yellow-300">No Balance Available</div>;
    }

    const bal = balances.find(x => x.asset === asset);
    if (!bal) {
      return null;
    }
    return <span>{`${bal.balance} ${asset.toUpperCase()}`}</span>;
  }, [asset, balances]);
}

export default OwnerAccounts;
