import OrganizationFilter from '../../../utils/OrganizationFilter';
import JSONFileDownload from '../../actions/JSONFileDownload';
import { adminAccessBanner } from '../../misc/AdminAccessBanner';
import { useUser } from '../../../hooks/useUser';

export type HistoryHeaderProps = {
  history: any[];
  organizationVisibility: Record<string, boolean>;
  setOrganizationVisibility: any;
};

export const HistoryHeader = ({
  history,
  organizationVisibility,
  setOrganizationVisibility,
}: HistoryHeaderProps) => {
  const { user } = useUser();

  return (
    <div className="flex justify-between py-4">
      <div className="flex items-center space-x-4">
        <h3 className="text-xl text-gray-100">Transaction History</h3>
        {adminAccessBanner(user)}
      </div>
      <OrganizationFilter
        hidden={!user.groups.includes('superadmins')}
        organizationVisibility={organizationVisibility}
        setOrganizationVisibility={setOrganizationVisibility}
      />
      <JSONFileDownload title="history" data={history} />
    </div>
  );
};
